
ion-button.customButtonCard{
  --background-activated: none;
  --background: none;
}

.header-briefing-detail{
  color: var(--color-purple-light);
  text-transform: uppercase;
  font-weight: 600;
}

.card-briefing{
  margin: 0px 0px 10px 0px;
}

.image-shelf{
  padding: 5px;
  border: 1px solid var(--ion-color-primary);
  border-radius: 5px;
}

.card-shelf{
  min-height: 575px;
}
@media screen and (min-width: 992px) and (max-width: 1400px) {
  .zoomOut {
    zoom: 70%
  }
}
.modal-fullscreen {
  --width: 100vw;
  --height: 100vh;
  --max-width: 100%;
  --max-height: 100%;
  --margin-left: 0;
  --margin-right: 0;
  --margin-top: 0;
  --margin-bottom: 0;
  --border-radius: 0;
}
@media (min-width: 1200px) and (max-width: 1400px) {
  .zoom-out{
          zoom:70%
      }
  }
