
ion-button.customButtonCard{
  --background-activated: none;
  --background: none;
}


@media screen and (min-width: 1025px) and (max-width: 1024px){
  /* Estilos para desktops */
}

.custom-modal {
  --width: 100%;
  height: 100%;
}