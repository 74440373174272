.button--icon {
  border: none;
}


/* ================ SUBITEMS ================ */

.subitem-group {
  width: 100%;
  background-color: #f8f8f8;
  border: 1px solid var(--color-gray-light-border);
  border-radius: 8px;
  margin: 2rem auto;
}

.subitem-group form {
  padding: 1rem;
}

.subitem-group .group-footer {
  width: 100%;
  padding: 0.5rem 1rem;
  border-top: 1px solid var(--color-gray-light-border);
}




ion-checkbox {
  --size: 28px !important;
  --checkbox-background-checked: #6815ec !important;
}

ion-checkbox::part(container) {
  padding: 0.25rem;
  border-radius: 6px !important;
  border: 2px solid #6815ec !important;
}

.header-row {
  background-color: #E6E6E6;
  /* Cor de fundo para a linha de cabeçalho */
  color: #000;
  /* Cor do texto para a linha de cabeçalho */
  text-align: center;
  /* Alinhamento do texto */
  border-radius: 1rem 1rem 0px 0px
}

.header-col {
  padding: 1rem 0;
  /* Espaçamento interno para a linha de cabeçalho */
}

.header-row:first-child,
.header-row:last-child {
  border: 0.1px solid #D3D3D3;
  border-radius: 0.5rem 0.5rem 0px 0px
}

.data-row {
  background-color: #fff;
}

.data-col {
  padding: 1rem 0;
  text-align: center;
  border: 0.1px solid #D3D3D3;
}


.value-text {
  color: #673ab7;
  /* Cor para os valores */
}

.repasse-text {
  color: #009688;
  /* Cor para os valores do repasse */
}