.step {
    background-color: #4b049a;
    color: #fff;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    width: fit-content;
    text-align: center;
    margin-bottom: 3rem;
}

.custom-modal {
    --width: 100%;
    --height: 100vh;
  }
