.MenuItem {
  background-color: #7A29F2;
  color: white
}


@media (max-width: 992px) {
  .menuDesk {
    display: none !important
  }

  .menuMobile {
    display: none !important;
  }

}

@media (min-width: 993px) {
  .menuDesk {
    display: flex !important
  }
  
 .menuMobile {
    display: none !important;
  }
}
.bg-white{
  background-color: white;
  height: 100vh;
}