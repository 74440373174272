ion-checkbox {
  --size: 32px;
  --background-checked: var(--color-purple-light);
  --background: none;
}

ion-checkbox::part(container) {
  border-radius: none;
  border: 2px solid var(--color-purple-light);
}

ion-item.customItemNoneBg {
  --background: none;
  --border: none;
  --border-style: none;
}

@media screen and (min-width: 1200px) and (max-width: 1400px) {
  .zoomOut {
    zoom: 80%
  }
}

.bgHeaderCardSuplier {
  background-color: #9a54ea4d;
  padding: 2rem;
  border-radius: 1rem;
  position: relative;
  height: 17rem;
  width: 20rem;

}

.HeaderCardSuplierTitle {
  width: 100%;
  color: #7a29f2;
  text-align: center;
  padding-bottom: 2rem;
  font-weight: bold;
  font-size: 1rem;
}

.bgBodyCardSuplier {
  width: 100%;
  background-color: #7a29f2;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  padding: 2rem;
  height: 12rem;
}

.bgBodyCardSuplierText {
  font-size: 0.85rem;
  color: white;
  text-align: center;
  margin: 0rem 1rem
}