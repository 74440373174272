@media (min-width: 1200px) and (max-width: 1400px) {
.zoom-out{
        zoom:70%
    }
}

table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    padding:1rem
  }
  
  td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  
  tr:nth-child(even) {
    background-color: #f0eeee;
  }
  .modal-semi-fullscreen {
    --width: 50vw;
    --height: 90vh;
    --max-width: 100%;
    --max-height: 100%;
    --margin-left: 0;
    --margin-right: 0;
    --margin-top: 0;
    --margin-bottom: 0;
    --border-radius: 0;
  }

  @media (max-width: 1270px) {
    .modal-semi-fullscreen {
      --width: 100vw;
      --height: 100vh;
     
    }
  }