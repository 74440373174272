/* content */
ion-content {
  --background: var(--color-ice);
}

/* headers */
ion-header{
  --ion-toolbar-background: var(--color-primary-light);
}

.icon-back{
  --color: (--color-white) !important;
}

/* headers bg white*/
ion-header.bg-ice {
  --ion-toolbar-background: var(--color-ice);
}

.bg-ice {
  --background: var(--color-ice);
  --border-color: none;
}

ion-menu.ios ion-item {
  color: var(--color-black);
}

/* reset toolbar border*/
ion-toolbar{
  --border-width: 0 0 0 !important;
  --color: var(--color-ice);

}

/* redefine card style*/
ion-card{  
  --background: var(--color-white);
  color: var(--color-black);
  margin: 12px 8px
}

ion-card ion-card-title{
    color: var(--color-black);
    font-size: 24px;
}

ion-card ion-tem {/* case have ion-item inside ion-card*/
  --inner-border-width: 0px 0px 0.55px 0px;
  --background: var(--color-ice);
}
/* final redefine card style*/

ion-item.custom {
  --background: #fff;
  --color: rgb(28, 28, 28);
  --placeholder-color: #ddd;
  --placeholder-opacity: .8;
  --border-radius: 5px;
  --border-style:none;
}

/* briefing card */
.card-briefing{
  min-height: 230px;
}

.ion-card-header-briefing{
  min-height: 113px;
}

ion-chip{
  cursor: default;
}

ion-modal.bg-ice {
  --ion-background-color: var(--color-ice);
}

ion-modal.bg-ice ion-text{
  --color: var(--color-ice);
}

/* set ion-content color*/
ion-content::part(scroll) {
  color: black;
  padding: 0px;
}

/* style of menu button */
ion-menu-button {
  --color: white;

  --border-radius: 0;
  --border-color: #000;
  --border-style: solid;
  --border-width: 1px;

  --box-shadow: 0 2px 6px 0 rgb(0, 0, 0, 0.25);
  
  --padding-top: 10px;
  --padding-bottom: 10px;
}

/* style of custom button (pink button) */
ion-button.custom{
    --color: #fff;
    --background: var(--color-purple-light);
    --border-radius: 30px;
}

ion-button.delete{
  --color: black;
  --background: var(--ion-color-danger);
  --border-radius: 15px;
  width: auto;
}

/* subscribe header alert */
.alert-title , .alert-sub-title{ 
  color: black !important;  
}

.alert-card .alert-wrapper {
  background-color: var(--color-white);
}

.alert-card .alert-message {
  color: var(--color-black);
}

button{
  word-wrap: break-word;
}

.customCheckBoxItem{
  border-radius: 10px;
}

.ion-list{
  background: var(--color-ice);
}

hr{
  width: 50%;
  background: var(--color-purple-light);
}

/* styles classes */
.d-flex{
    display: flex;
}

.mr-10{
  margin-right: 10px;
}

.p-10{
  padding: 10px;
}

.f-right{
  float: right;
}

.mb-20{
  margin-bottom: 20px;
}

.font-color-white{
  --color: var(--color-white) !important;
}


@media screen and (max-width: 767px) {

  /* Estilos para dispositivos móveis */

}

@media screen and (min-width: 768px) and (max-width: 1024px) {

  /* Estilos para tablets */

}

@media screen and (min-width: 1025px) {
  /* Estilos para desktops */
  ion-menu{
    width: 330px;
  }
}