
ion-button.customButtonCard{
  --background-activated: none;
  --background: none;
}

ion-toggle{
  --background: #d3d3d3;
}

@media screen and (max-width: 767px) {

  /* Estilos para dispositivos móveis */

}

@media screen and (min-width: 768px) and (max-width: 1024px) {

  /* Estilos para tablets */

}

@media screen and (min-width: 1025px) {
  /* Estilos para desktops */
  .categories-panel{
    display: flex;
  }
}

