.loaderpage{
  width: 100%;
  height: 100vh;
  display: flex;
  alignItems: center;
  justifyContent: cente;
}

@keyframes loadericon-animation {
  from {
    transform: rotate(0deg)
  }
  to {
    transform: rotate(360deg)
  }
}

.loadercontent{
  /* Display & Box Model */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* gap: 0.5rem; */
}

.loadercontent p{
    /* Color */
    color: var(--color-purple-light);
}

.loadericon{
  animation: loadericon-animation 0.5s linear 0s infinite;
  color: var(--color-purple-light);
}