.containerAreas {
    max-width: 80rem;
}

.zoomOutAreas {
    width: 14rem;
    cursor: pointer;
}

.selected {
    background-color: var(--color-primary-light);
    color:#fff;
    transition: 0.25s;
}

.areaName {
    text-align: center;
    font-size: 0.7rem
}

@media screen and (min-width: 992px) and (max-width: 1400px) {
    .containerAreas {
        max-width: 65rem;
    }
    .zoomOut {
        zoom: 60%
    }

    .areaName {
        text-align: center;
        font-size: 0.57rem
    }

    .zoomOutAreas {
        width: 8rem;
    }
}