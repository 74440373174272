.heading{
  padding-bottom: 0.5rem;
  position: relative;
  font-weight: 700
}

.heading::before{
  /* Display & Box Model */
  content: '';
  width: 3rem;
  height: 0.2rem;

  /* Colors */
  background-color: var(--color-primary-light);

  /* Position */
  position: absolute;
  bottom: 0;

  /* Others */
  border-radius: 0.1rem;
}